import request from '@/utils/request';
import { isDevEnv, setAppName } from "@/utils/common";
const prod_appName = setAppName()
const dev_appName = "huanxinxiang-shuchuang"
const appName = isDevEnv ? dev_appName : prod_appName;

// 支付宝签约
export const alipayNoSignSMSPay = (params, data) => request({
  url: '/huanxinxiang-service/v1/alipay-no-sign-pay/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  params,
  data,
});
